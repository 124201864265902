import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import { loadPrinterGroup } from '@/model/print/printerCommonApi'
import { isEmpty } from 'lodash'
import IKUtils from 'innerken-js-utils'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'deviceId',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
    form: false,
  },
  printerName: {
    displayName: 'printerName',
  },
  isSingleLinePrint: {
    type: IKDataEntity.Types.Boolean,
    displayName: 'isSingleLinePrint',
  },
  upsideDown: {
    header: false,
    type: IKDataEntity.Types.Boolean,
  },
  defaultRetryCount: {
    formConfig: {
      default: '0',
    },
  },
  printerTypeId: {
    // type: IKDataEntity.Types.Option,
    displayName: 'printerTypeName',
    formConfig: {
      type: {
        itemText: 'typeName',
      },
      requiredEdit: false,
    },

  },
  sn: {
    displayName: 'sn',
  },

}
const receptionPrinterId = 3
const receptionPrinterName = async () => (await loadPrinterGroup()).find(i => i.id === receptionPrinterId).name
// const printerGroup = await loadPrinterGroup()

const config = {

  load: async function (filter) {
    const printerList = (await hillo.get('Printer.php', {
      op: 'showAllPrinterStatus',
      ...filter,
    })).content

    const printerGroup = await loadPrinterGroup()
    // console.log(printerGroup, 'printerGroup')

    const res = printerList.map(item => {
      item.groupId = item.groupId?.split(',').map(i => parseInt(i))
      item.isReceptionPrinter = false
      if (!isEmpty(item.groupId) && item.groupId.includes(receptionPrinterId)) { item.isReceptionPrinter = true }

      const matchedPrinterGroup = isEmpty(item.groupId) ? {} : (printerGroup?.find(i => parseInt(i.id) === item.groupId[0]) ?? {})
      return Object.assign({}, matchedPrinterGroup, item)
    })
    return res
  },
  add: function (item) {
    if (item.isReceptionPrinter) {
      if (!item.groupId.includes(receptionPrinterId)) {
        item.groupId.push(receptionPrinterId)
      }
      if (!item.groupName.split(',').includes(receptionPrinterName)) {
        item.groupName += receptionPrinterName
      }
    }

    item.groupIds = JSON.stringify(item.groupId)
    item.name = item.printerName
    item.printerSectionID = JSON.stringify(item.sectionIds)


    // if (!item.isReceptionPrinter) {
    //   const tempItem = item.groupId.filter(i => i !== 3)
    //   item.groupId = tempItem
    // }

    return hillo.postWithUploadFile('Printer.php?op=add', item, {
      showLoading: true,
    })
  },
  edit: async function (item, showLoading = true) {
    if (!item.isReceptionPrinter) {
      const tempItem = item.groupId.filter(i => i !== receptionPrinterId)
      item.groupId = tempItem
      const tempGroupName = item.groupName.split(',').filter(i => i !== receptionPrinterName).toString()
      item.groupName = tempGroupName
    } else {
      if (!item.groupId.includes(receptionPrinterId)) {
        item.groupId.push(receptionPrinterId)
      }
      if (!item.groupName.split(',').includes(receptionPrinterName)) {
        const tempGroupName = item.groupName.split(',').push(receptionPrinterName).toString()
        item.groupName = tempGroupName
      }
    }

    item.groupIds = JSON.stringify(item.groupId)
    item.name = item.printerName
    item.printerSectionID = JSON.stringify(item.sectionIds)
    // item.groupName = (await loadPrinterGroup()).map(i => i.)

    const printerGroupData = IKUtils.deepCopy(item)
    printerGroupData.id = item.groupId[0]
    printerGroupData.name = item.printerName

    console.log(printerGroupData, 'printerGroupData')
    await hillo.post('Printer.php?op=updatePrinterGroups', printerGroupData)

    return hillo.postWithUploadFile('Printer.php?op=edit', item, {
      showLoading,
    })
  },
  remove: function (id) {
    return hillo.post('Printer.php?op=remove', { id: id })
  },

}

export default IKDataEntity.ModelFactory(entity, config)
