<template>
  <div>
    <v-card
      class="pa-4"
      elevation="0"
      style="position: relative; border: 2px solid #EDEDED"
    >
      <div class="d-flex align-center">
        <div
          v-if="noCombinedPrinter"
          class="textNoWrap"
          style="color: lightsalmon"
        >
          <!--          缺少打印机名称-->
          {{ localPrinter.name }}
        </div>
        <div
          v-else
          class="text-body-1 textNoWrap"
        >
          {{ localPrinter.name }}
        </div>

        <v-spacer/>
        <v-chip
          :color="localPrinter.status === 1 ? 'success' : 'error'"
          class="px-2"
          label
          small
        >
          <v-icon left>
            mdi-circle-medium
          </v-icon>
          <span v-if="noCombinedPrinter">
            {{ $t('noConnected') }}
          </span>
          <span v-else>
            {{ localPrinter.status === 1 ? $t('normal') : $t('unnormal') }}
          </span>
        </v-chip>


        <div style="display: flex; text-align: center">
          <v-menu
            close-on-content-click
            left
            offset-overflow
            offset-y
            rounded
          >
            <template #activator="{on, attrs}">
              <v-icon
                icon
                style="border-radius: 12px"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list
              outlined
              width="200px"
            >
              <v-list-item
                v-if="localPrinter.isPrinter"
                @click="editPrinter"
              >
                {{ $t('edit') }}
              </v-list-item>
              <v-list-item @click="deletePrinter">
                {{ $t('delete') }}
              </v-list-item>
              <!--              <v-list-item-->
              <!--                v-if=" localPrinter && localPrinter.printerId"-->
              <!--                @click="testPrint(localPrinter.groupId[0], 3)"-->
              <!--              >-->
              <!--                {{ $t('test') }}-->
              <!--              </v-list-item>-->
              <v-list-item
                v-if="localPrinter.isPrinter"
                @click="testPrinter"
              >
                {{ $t('switchAndTestPaperSize') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-card
        class="my-2 d-flex justify-center"
        elevation="0"
        @click="editPrinter"
      >
        <div
          v-if="isReceptionPrinter"
          style="position: absolute; left: 0px; z-index: 1"
        >
          <v-chip class="primary">
            {{ $t('bill_printer') }}
          </v-chip>
        </div>
        <template v-if="localPrinter.printerId">
          <v-img
            v-if="isStickerPrinter"
            :src="imgSrc"
            height="196"
          />
          <v-img
            v-else
            :src="imgSrc"
            contain
            height="196"
            style="flex-grow: 0"
            width="60%"
          />
        </template>
        <template
          v-else
          style="font-size: 48px; font-weight: bolder"
        >
          <v-card
            class="elevation-0 d-flex justify-center align-center"
            flat
            height="196"
          >
            <v-card
              class="px-8 py-4 text-center"
              flat
              style="border: dashed 1px"
              @click="addPrinter"
            >
              <v-icon size="60">
                mdi-plus
              </v-icon>
              <div>{{ $t('addNewPrinter') }}</div>
            </v-card>
          </v-card>
        </template>
      </v-card>

      <div
        class="pa-4"
        style="background-color: #EDEDED; border-radius: 12px"
      >
        <div
          class="text-caption textNoWrap"
        >
          <v-icon
            left
            size="18"
          >
            mdi-printer-settings
          </v-icon>
          <span
            v-if="noCombinedPrinter"
            style="color: lightsalmon"
          >
            {{ $t('lack_print_type') }}
          </span>
          <span v-else>
            {{ localPrinter.printerTypeName }}
          </span>
        </div>


        <div
          class="text-caption textNoWrap"
        >
          <v-icon
            left
            size="18"
          >
            mdi-sticker
          </v-icon>

          <template
            v-if="noCombinedPrinter"
          >
            <span style="color: lightsalmon">
              {{ $t('lackSizeInfo') }}
            </span>
          </template>
          <template v-else>
            <span v-if="isStickerPrinter">
              {{ localPrinter.height }}x{{ localPrinter.width }} (mm) | {{ localPrinter.gap }} (mm)
            </span>
            <span v-else>
              {{ localPrinter.width }} (mm)
            </span>
          </template>
        </div>

        <div
          class="text-caption textNoWrap"
        >
          <v-icon
            left
            size="18"
          >
            {{ deviceIcon }}
          </v-icon>
          <span
            v-if=" !localPrinter || !localPrinter.sn"
            style="color: lightsalmon"
          >
            {{ $t('lackSN') }}
          </span>
          <span v-else>
            {{ localPrinter.sn }}
          </span>
        </div>


        <div
          class="mt-1 d-flex justify-start"
        >
          <div
            class="textNoWrap"
            style="display: flex; text-align: center"
          >
            {{ $t('printerCount', { printerCount: localPrinter.totalDishesCount }) }}
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import EditDeviceDialog from '@/views/AadenPages/print/fragment/EditDeviceDialog'
  import IKUtils from 'innerken-js-utils'
  import { IkEntityForm } from 'metaflow-js'
  import PrinterNormal from '@/model/print/PrinterNormal'
  import PrinterStick from '@/model/print/PrinterStick'

  import PrinterType from '@/model/print/PrinterType'
  import { printerTest } from '../../../../common/deviceTest'
  import EditDialog from './EditDialog'


  export default {
    name: 'DeviceCard',
    components: {
      EditDialog,
      EditDeviceDialog,
      IkEntityForm,
    },
    props: {
      printerObject: {
        type: Object,
        default: () => {
        },
      },
      isReceptionPrinter: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {

        showEditDeviceDialog: false,
        localPrinter: {
          sn: '',
          printerName: '',
          name: '',
          height: '',
          width: '',
          gap: '',
        },
        PrinterNormal: PrinterNormal,
        PrinterStick: PrinterStick,
        printerList: [],
        printerType: [],
      }
    },
    computed: {
      imgSrc () {
        let imgSrc = require('@/assets/printers/epson-tm30.png')
        if (this.isStickerPrinter) {
          imgSrc = require('@/assets/printers/usb-sticker.png')
        }
        return imgSrc
      },
      isUsbPrinter () {
        return this.localPrinter.sn?.includes('/dev')
      },
      isStickerPrinter () {
        // return this.localPrinter.printerTypeId === '9' || this.localPrinter.printerTypeId === '10'
        return this.localPrinter.isStickerPrinter
      },
      deviceIcon () {
        let deviceIcon = 'mdi-lan'
        if (this.isUsbPrinter) {
          deviceIcon = 'mdi-usb'
        }
        return deviceIcon
      },
      noCombinedPrinter () {
        return !this.localPrinter || !this.localPrinter.printerId
      },
      hasCombinedPrinter () {
        return !this.noCombinedPrinter
      },

    },
    mounted () {
      this.localPrinter = IKUtils.deepCopy(this.printerObject)
      console.log(this.localPrinter, 'printer')
      if (!this.localPrinter.totalDishesCount) {
        this.localPrinter.totalDishesCount = 0
      }
      this.reload()
    },
    methods: {

      editPrinter () {
        if (this.hasCombinedPrinter) {
          // this.showEditDeviceDialog = true
          this.$emit('edit')
        }
      },

      addPrinter () {
        this.$emit('add')
      },

      testPrinter () {
        this.$emit('test')
        console.log('test Device Card')
      },
      deletePrinter () {
        this.$emit('delete')
      },

      async reload () {
        this.printerType = await PrinterType.getList()
        this.printerList = this.isStickerPrinter ? await PrinterStick.getList(true) : await PrinterNormal.getList(true)
      },

      async testPrint (printGroupId, testCount) {
        await printerTest(printGroupId, testCount)
        IKUtils.toast()
      },

    },
  }
</script>

<style scoped>

.textNoWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
