import { IKDataEntity } from 'metaflow-js'
// import hillo from 'hillo'
import Printer from './Printer'
// import { loadPrinterGroup } from '@/model/print/printerCommonApi'
// import { isEmpty } from 'lodash'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'deviceId',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
    form: false,
  },
  printerName: {
    displayName: 'printerName',
  },
  isSingleLinePrint: {
    type: IKDataEntity.Types.Boolean,
    displayName: 'isSingleLinePrint',
  },
  upsideDown: {
    header: false,
    type: IKDataEntity.Types.Boolean,
  },
  defaultRetryCount: {
    formConfig: {
      default: '0',
    },
  },
  width: {
    type: IKDataEntity.Types.Integer,
    formConfig: {
      default: 55,
    },
  },
  height: {
    type: IKDataEntity.Types.Integer,
    formConfig: {
      default: 30,
    },
  },
  gap: {
    formConfig: {
      default: 1.5,
      rule: [],
    },
  },
  printerTypeId: {
    formConfig: {
      type: {
        itemText: 'typeName',
      },
      requiredEdit: false,
    },
    displayName: 'printerTypeName',
  },
  sn: {
    displayName: 'sn',
  },

}

const config = {
  load: async function (filter) {
    const res = await Printer.load(filter)
    return res.filter(i => i.printerTypeId === '9' || i.printerTypeId === '10')
  },
  add: function (item) {
    Printer.add(item)
  },
  edit: async function (item) {
    return Printer.edit(item)
  },
  remove: function (id) {
    return Printer.remove(id)
  },
}

export default IKDataEntity.ModelFactory(entity, config)
