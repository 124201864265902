<template>
  <v-navigation-drawer
    v-model="realDialog"
    fixed
    :width="width"
    right
    touchless
    temporary
  >
    <v-container
      class="pa-6"
      style="background-color: #F9FBFC"
    >
      <div class="d-flex align-center mb-8">
        <v-btn
          class="mr-5 rounded"
          height="36px"
          width="36px"
          tile
          icon
          @click="close"
        >
          <v-icon size="24">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <div class="text-h3 font-weight-bold">
          {{ $t('device') }}/{{ localPrinter.name }}
        </div>
        <v-spacer/>
      </div>

      <v-card
        class="pa-2"
      >
        <div class="d-flex justify-space-between mx-4">
          <div class="text-h3 d-flex align-center">
            {{ $t('thermal_paper_width') }}
          </div>

          <div>
            <v-tabs v-model="tab">
              <v-tab>{{ $t('test') }}</v-tab>
              <v-tab>{{ $t('change_paper_size') }}</v-tab>
            </v-tabs>
          </div>
        </div>

        <div>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card
                class="d-flex align-center elevation-0"
              >
                <div class="ma-4">
                  <div
                    class="text-h4 my-2 pa-2 rounded-xl"
                    style="display: grid; grid-template-columns: 2fr 2fr; "
                  >
                    <v-img
                      :src="localPrinter?.isStickerPrinter ? require('@/assets/printers/EtikettWidth.png') : require('@/assets/printers/thermalpaperWidth.png') "
                      height="196"
                      contain
                      width="50%"
                      style="border: 1px solid grey"
                      class="rounded-xl ml-4"
                    />
                    <div class="d-flex justify-center align-center">
                      {{ $('width') }}： {{ localPrinter?.width }} mm
                    </div>
                  </div>

                  <div
                    v-if="localPrinter?.isStickerPrinter"
                    class="text-h4 "
                    style="display: grid; grid-template-columns: 2fr 2fr; "
                  >
                    <v-img
                      src="@/assets/printers/EtikettHeight.png"
                      height="196"
                      contain
                      width="50%"
                      style="border: 1px solid grey"
                      class="rounded-xl ml-4"
                    />
                    <div class="d-flex justify-center align-center">
                      {{ $t('height') }}：{{ localPrinter.height }} mm
                    </div>
                  </div>

                  <div
                    v-if="localPrinter?.isStickerPrinter"
                    class="text-h4 my-2"
                    style="display: grid; grid-template-columns: 2fr 2fr; "
                  >
                    <v-img
                      src="@/assets/printers/EtikettGap.png"
                      height="196"
                      contain
                      width="50%"
                      style="border: 1px solid grey"
                      class="rounded-xl ml-4"
                    />
                    <div class="d-flex justify-center align-center">
                      {{ $t('gap') }}：{{ localPrinter.gap }} mm
                    </div>
                  </div>
                </div>
              </v-card>
              <v-card-actions class="ma-4">
                <v-spacer/>
                <v-btn
                  class="primary"
                  @click="testPrint(localPrinter.groupId[0], 3)"
                >
                  {{ $t('test') }}
                </v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item>
              <v-card class="elevation-0">
                <div class="ma-4">
                  <div>
                    {{ $t('width') }}：
                    <v-text-field
                      v-if="localPrinter.isStickerPrinter"
                      v-model="localPrinter.width"
                      outlined
                      suffix="mm"
                    />

                    <v-select
                      v-else
                      v-model="localPrinter.width"
                      :items="['80','58']"
                      outlined
                      suffix="mm"
                      :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
                    />
                  </div>

                  <div v-if="localPrinter.isPrinter && localPrinter.isStickerPrinter">
                    <div>
                      {{ $t('height') }}：
                      <v-text-field
                        v-model="localPrinter.height"
                        outlined
                        suffix="mm"
                      />
                    </div>
                    <div>
                      {{ $t('gap') }}：
                      <v-text-field
                        v-model="localPrinter.gap"
                        outlined
                        suffix="mm"
                      />
                    </div>
                  </div>
                </div>


                <v-card-actions class="mt-8 mx-4">
                  <v-spacer/>
                  <v-btn
                    class="primary"
                    @click="save"
                  >
                    {{ $t('submit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
  import { printerTest } from '../../../../common/deviceTest'
  import IKUtils from 'innerken-js-utils'
  import Printer from '../../../../model/print/Printer'


  export default {
    name: 'ChangeSizeAndTestDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: 'min(700px, calc(100vw - 300px))',
      },
      printerObject: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        realDialog: false,
        thermalWidth: 80,
        thermalPaperSize: ['80', '55', '40'],
        tab: null,
        localPrinter: { name: '' },

      }
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.realDialog = val
          if (this.printerObject) { this.localPrinter = Object.assign(this.localPrinter, this.printerObject) }
        },
      },
      realDialog (val) {
        this.$emit('input', val)
      },

    },

    mounted () {

    },
    methods: {
      async testPrint (printGroupId, testCount) {
        await printerTest(printGroupId, testCount)
        IKUtils.toast()
      },
      async save () {
        const printer = Object.assign({}, this.localPrinter)
        printer.id = printer.printerId
        printer.width = this.thermalWidth
        await Printer.edit(printer, false)
        IKUtils.toast()
        this.tab = 0
      },
      close () {
        this.$emit('need-refresh')
        this.realDialog = false
      },
    },
  }
</script>

<style scoped>

</style>
