<template>
  <v-card class="pa-4">
    <div
      class="d-flex align-center"
    >
      <div class="text-h3 font-weight-bold">
        {{ $t('addNewPrinter') }}
      </div>
      <v-spacer/>

      <v-btn
        class="ml-2"
        style="color: black"
        @click="handleCancle"
      >
        {{ $t('cancel') }}
      </v-btn>
    </div>

    <template v-if="step===0">
      <div class="text-subtitle-1 mt-4">
        {{ $t('choosePrinterType') }}
      </div>

      <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px"
      >
        <v-card
          v-for="item in supportPrinters"
          :key="item.id"
          class="pa-2"
          outlined
          @click="choosePrinterType(item)"
        >
          <v-card
            elevation="0"
            height="160px"
          >
            <v-img
              :src="item.image"
              contain
              height="160px"
            />
          </v-card>
          <div class="mt-4">
            {{ item.name }}
          </div>
        </v-card>
      </div>
    </template>
    <template v-if="step===1">
      <div class="text-subtitle-1 mt-4">
        {{ $t('choosePrinterConnection') }}
      </div>
      <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px"
      >
        <v-card
          class="pa-2 text-center"
          outlined
          @click="chooseInterface('usb')"
        >
          <v-card
            class="d-flex align-center justify-center"
            elevation="0"
            height="96px"
          >
            <v-icon size="48">
              mdi-usb
            </v-icon>
          </v-card>
          <div class="mt-4">
            USB
          </div>
        </v-card>
        <v-card
          class="pa-2 text-center"
          outlined
          @click="chooseInterface('lan')"
        >
          <v-card
            class="d-flex align-center justify-center"
            elevation="0"
            height="96px"
          >
            <v-icon size="48">
              mdi-lan
            </v-icon>
          </v-card>
          <div class="mt-4">
            {{ $t('ethernetCable') }}
          </div>
        </v-card>
      </div>
    </template>
    <template v-if="step===2">
      <div v-if="checkingExistence">
        <div class="text-subtitle-1 my-4">
          {{ $t('checkingWhetherThePrinterExists') }}
        </div>
      </div>
      <div v-else>
        <div class="text-subtitle-1 mt-4">
          <p>{{ $t('inputStep2Title', { step2Title: step2Title }) }}</p>
        </div>
        <div class="text-body-2 my-4">
          <div
            v-if="selectedInterface==='usb'"
          >
            {{ $t('checkEquipmentList') }}
          </div>

          <div
            v-else
          >
            {{ $t('clickFeedButton') }}
          </div>
        </div>
      </div>

      <v-text-field
        v-model="deviceSN"
        :placeholder="step2Title"
        :rules="step2Rule"
        outlined
        @keydown.enter="setSn"
      />

      <v-btn
        :loading="checkingExistence"
        :disabled="snIsExist"
        block
        color="primary"
        elevation="0"
        @click="setSn"
      >
        {{ $t('sure') }}
      </v-btn>
    </template>

    <template v-if="step===3">
      <template v-if="isSticker">
        <div class="text-subtitle-1 mt-4">
          {{ $t('labelSizeSetting') }}
        </div>

        <v-card
          class="mt-4 pa-2"
          outlined
        >
          <div class="text-subtitle-1">
            {{ $t('instructions') }}
          </div>
          <div class="text--secondary text-body-2">
            <p v-html="$t('labelPaperRule')"/>
          </div>
        </v-card>
        <div class="mt-4">
          <div class="d-flex">
            <div>
              <div class="text-overline">
                {{ $t('paperLong') }}
              </div>
              <v-text-field
                v-model="stickHeight"
                :placeholder="$t('unitMM')"
                dense
                hide-details
                outlined
                type="number"
              >
                <template #append>
                  <div style="margin-top: 2px">
                    mm
                  </div>
                </template>
              </v-text-field>
            </div>
            <div style="width: 16px"/>
            <div>
              <div class="text-overline">
                {{ $t('paperHigh') }}
              </div>
              <v-text-field
                v-model="stickWidth"
                :placeholder="$t('unitMM')"
                dense
                hide-details
                outlined
                type="number"
              >
                <template #append>
                  <div style="margin-top: 2px">
                    mm
                  </div>
                </template>
              </v-text-field>
            </div>
          </div>

          <div class="mt-4">
            <div class="text-overline">
              {{ $t('paperSpacing') }}
            </div>
            <v-text-field
              v-model="stickGap"
              :placeholder="$t('unitMM')"
              dense
              hide-details
              outlined
              type="number"
            >
              <template #append>
                <div style="margin-top: 2px">
                  mm
                </div>
              </template>
            </v-text-field>
          </div>
          <div style="width: 16px"/>
        </div>
      </template>

      <template v-else>
        <div class="text-subtitle-1 mt-4">
          {{ $t('labelSizeSetting') }}
        </div>
        <v-card
          outlined
          class="my-4 pa-2 "
        >
          <div class="text-subtitle-1">
            {{ $t('instructions') }}
          </div>
          <div class="text--secondary text-body-2">
            <p v-html="$t('thermalPaperRule')"/>
          </div>
        </v-card>
        <v-select
          v-model="thermalWidth"
          :placeholder="$t('unitMM')"
          dense
          hide-details
          outlined
          :items="thermalPaperSize"
          :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
        >
          <template #append>
            <div style="margin-top: 2px">
              mm
            </div>
          </template>
        </v-select>
      </template>

      <div class="mt-4">
        <v-checkbox
          v-model="isThermalPaper"
          :label="$t('determineThePaper')"
          class="ml-n2"
          dense
          hide-details
        />
      </div>
      <div class="mt-4">
        <v-btn
          block
          color="primary"
          elevation="0"
          @click="handlePaperSize"
        >
          <v-icon left>
            mdi-arrow-right
          </v-icon>
          {{ $t('nextStep') }}
        </v-btn>
      </div>
    </template>

    <template v-if="step===4">
      <v-form>
        <div class="text-subtitle-1 mt-4">
          {{ $t('printSetting') }}
        </div>
        <div class="text-overline">
          {{ $t('printerName') }}<span class="red--text">*</span>
        </div>

        <v-text-field
          v-model="devicePrinterName"
          clearable
          hide-details
          outlined
          :disabled="!!printerObject"
        />

        <div class="text-subtitle-1 mt-4">
          {{ $t('printGroupOption') }}
        </div>
        <div class="mt-4">
          <div class="mt-4">
            <v-checkbox
              v-model="printerCount"
              :label="$t('printTwoCopies')"
              class="ml-n2"
              dense
              hide-details
            />
          </div>
          <div class="mt-4">
            <v-checkbox
              v-model="upsideDown"
              :label="$t('printUpsideDown')"
              class="ml-n2"
              dense
              hide-details
            />
          </div>
          <div class="mt-4">
            <v-btn
              block
              color="primary"
              elevation="0"
              @click="handleAddPrinter"
            >
              <v-icon left>
                mdi-save
              </v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </template>
    <template v-if="step===5">
      <div class="text-subtitle-1 mt-4">
        🎉 {{ $t('addSuccess!') }}
      </div>
    </template>
  </v-card>
</template>

<script>
  import { Rules, showSuccessMessage } from '@/common/utils'
  import IKUtils from 'innerken-js-utils'
  import DeviceList from '@/model/print/DeviceList'
  import PrinterGroup from '@/model/print/PrinterGroup'
  import PrinterType from '@/model/print/PrinterType'
  import { isBlank } from '@/common/Utlis/api'
  import { loadPrinterGroup } from '@/model/print/printerCommonApi'
  import i18n from '@/i18n'

  export default {
    name: 'AddNewDeviceDialog',
    props: {
      printerObject: {
        type: Object,
        default: () => {},
      },
    },
    data: function () {
      return {
        printerGroupReceptionId: 3, // 前台打印分组Id
        thermalPaperSize: ['80', '55', '40'],
        valid: true,
        checkStickPaperSecond: false,
        checkStickPaperFirst: false,
        step: 0,
        selectedPrinter: null,
        selectedInterface: null,
        deviceSN: '',
        devicePrinterName: '',
        newPrinterGroupId: null,
        search: '',
        checkingExistence: false,
        Rules: Rules,
        supportPrinters: [
          {
            name: i18n.t('receiptsPrinter'),
            image: require('@/assets/printers/epson-tm30.png'),
            id: 1,
          },
          {
            name: i18n.t('labelPrinter'),
            image: require('@/assets/printers/usb-sticker.png'),
            id: 2,
          },
        ],

        availablePrinterConnections: ['usb', 'network'],
        loading: false,

        defaultRetryCount: '0',
        isSingleLinePrint: true,
        isSingleOrderPrint: false,
        printerCount: '2',
        upsideDown: false,
        thermalWidth: '80',
        stickHeight: '40',
        stickWidth: '60',
        stickGap: '3',
        defaultPrinterTypeList: [],
        BonPrinterName: null,
        defaultPrinterList: [i18n.t('waterBar'), i18n.t('frontDesk'), i18n.t('sushi'), i18n.t('ironPlate'), i18n.t('pan'), i18n.t('afterKitchen')],
        localPrinter: null,
        isThermalPaper: false,
        snList: [],
        deviceList: [],

      }
    },
    computed: {
      isUsb () {
        return this.selectedInterface === 'usb'
      },
      step2Title () {
        return this.isUsb ? i18n.t('USBDeviceName') : i18n.t('networkCableDeviceIp')
      },
      step2Rule () {
        return [this.isUsb ? Rules.required : Rules.ip && Rules.required]
      },
      isSticker () {
        return this.selectedPrinter?.id === 2
      },

      ablePrinterName () {
        const res = !(this.printerObject?.name)
        console.log(this.printerObject, res, 'ablePrinterName')
        return res
      },


      printerTypeId () {
        let printerTypeId = '7'
        if (!this.isSticker && this.isUsb) {
          printerTypeId = '6' // 80mm离线打印机
        }
        if (this.isSticker && this.isUsb) {
          printerTypeId = '9' // 离线Sticker打印机
        }
        if (this.isSticker && !this.isUsb) {
          printerTypeId = '10' // 网线贴纸打印机
        }
        return printerTypeId
      },

      printerTypeName () {
        return this.defaultPrinterTypeList.find(i => i.id === this.printerTypeId)?.typeName ?? 'Printer Type ID is not in the List'
      },

      snIsExist () {
        return this.deviceList.map(item => item.sn).includes(this.deviceSN)
      },
    },
    watch: {
      async printerObject (val) {
        this.localPrinter = val
        this.defaultPrinterTypeList = await PrinterType.getList()
        this.devicePrinterName = this.printerObject ? this.printerObject.name : ''
        this.defaultPrinterGroupList = await PrinterGroup.getList()
        this.BonPrinterName = this.defaultPrinterGroupList.find(i => i.id === this.printerGroupReceptionId).name
        this.deviceList = await DeviceList.getList()
        console.log(this.devicePrinterName, 'deviceName x')
        console.log(this.localPrinter, 'this.localPrinter x')
        console.log(this.printerObject, 'this.printerObject x')
      },

    },
    async mounted () {
      this.deviceList = await DeviceList.getList()
      this.devicePrinterName = this.printerObject ? this.printerObject.name : ''
      // this.localPrinter = Object.assign(this.localPrinter, this.printerObject)
      console.log(this.devicePrinterName, 'deviceName y')
      console.log(this.localPrinter, 'this.localPrinter y')
      console.log(this.printerObject, 'this.printerObject y')
    },

    methods: {
      choosePrinterType (type) {
        this.selectedPrinter = type
        this.step++
      },
      chooseInterface (type) {
        this.selectedInterface = type
        this.step++
        if (type === 'usb') {
          this.deviceSN = '/dev/usb/lp'
        }
        if (type === 'lan') {
          this.deviceSN = '192.168.'
        }
      },
      restart () {
        this.step = 0
        this.deviceSN = ''
        // this.devicePrinterName = ''
        this.checkStickPaperSecond = false
        this.checkStickPaperFirst = false
        this.isThermalPaper = false
        this.localPrinter = null
      },
      async setSn () {
        if (isBlank(this.deviceSN)) {
          await IKUtils.showError(this.$t('device_SN_can_not_be_empty'))
          return
        }
        this.checkingExistence = true
        // await new Promise(resolve => setTimeout(resolve, 3_000))

        // 自动检查
        this.checkingExistence = false
        this.step++
      },

      async handleAddPrinter () {
        if (isBlank(this.devicePrinterName)) {
          await IKUtils.showError('打印组名称不能为空')
          return
        }

        await this.actionAnd(async () => {
          // PrinterGroup
          const printerGroupData = {
            name: this.devicePrinterName,
            isSingleOrderPrint: this.isSingleOrderPrint,
            isSingleLinePrint: this.isSingleLinePrint,
            upsideDown: this.upsideDown,
          }
          if (!this.printerObject) {
            await PrinterGroup.add(printerGroupData)
            this.defaultPrinterGroupList = await loadPrinterGroup()
            this.newPrinterGroupId = parseInt(this.defaultPrinterGroupList[this.defaultPrinterGroupList.length - 1].id)
            console.log(this.newPrinterGroupId, 'newPriterGroupId x')
          } else {
            this.newPrinterGroupId = parseInt(this.localPrinter.printerGroupId)
            console.log(this.newPrinterGroupId, 'newPriterGroupId y')
          }


          // Printer
          const printerData = {
            id: -1,
            sn: this.deviceSN,
            printerName: this.isReceptionPrinter ? this.devicePrinterName + ',' + this.BonPrinterName : this.devicePrinterName,
            printerTypeId: this.printerTypeId,
            identity: this.devicePrinterName,
            key: this.devicePrinterName,
            groupId: this.isReceptionPrinter ? [this.newPrinterGroupId, this.printerGroupReceptionId] : [this.newPrinterGroupId],
            sectionIds: [1],
            height: this.stickHeight,
            width: this.isSticker ? this.stickWidth : this.thermalWidth,
            gap: this.stickGap,
          }

          await DeviceList.add(printerData)
          this.restart()
        })

        // this.step++
        this.step = 0
        this.$emit('reload')
      },
      async actionAnd (action) {
        this.loading = true
        try {
          await action()
          this.$emit('reload', true)
          showSuccessMessage()
        } catch (e) {
        }
        this.loading = false
        this.deviceSN = null
      },
      async handleNext () {
        await this.validate()
        if (this.stickHeight && this.stickWidth && this.stickGap && this.checkStickPaperFirst) {
          this.step++
        }
      },
      validate () {
        this.$refs.form.validate()
      },
      handleCancle () {
        this.$emit('cancle')
        this.restart()
      },
      handlePaperSize () {
        if (this.isThermalPaper) { this.step++ } else { IKUtils.showConfirm('Please confirm the paper is thermal', '') }
      },
    },
  }
</script>

<style scoped>

</style>
