import hillo from 'hillo'

/**
 * if printGroupId and testCount are both null, all dishes will be printed
 * @param printGroupId
 * @param testCount: the count of dishes or products which should be printed out
 * @returns {Promise<void>}
 */
export async function printerTest (printGroupId, testCount) {
  await hillo.post('Complex.php', { printGroupId, testCount })
}
