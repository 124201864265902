<template>
  <v-dialog v-if="showDialog">
    <v-card>
      <v-card-title>
        <h3>{{ $t('name') }}</h3>
      </v-card-title>
      <v-card-text/>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EditDeviceDialog',
    props: {
      printerObject: {
        type: Object,
        default: () => {},
      },
      showDialog: {
        type: Boolean,
        default: false,
      },
    },
    watch () {

    },
  }
</script>

<style scoped>

</style>
