<template>
  <v-card>
    <div class="d-flex align-center pa-4">
      <div class="text-h3 font-weight-bold">
        {{ $t('deleteThisPrinter') }}
      </div>
    </div>
    <div class="pa-4">
      {{ $t('moveDishesToPrinter', { dishesLength: selectPrinter.totalDishesCount }) }}
    </div>
    <div class="pa-4">
      <v-item-group v-model="moveDishesPrinter">
        <div
          style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 8px"
        >
          <v-item
            v-for="x in waitSelectedPrinter"
            :key="x.id"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="pa-2 d-flex align-center justify-center"
              elevation="0"
              outlined
              @click="toggle()"
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column"
                  style="height: 100%;position: relative"
                >
                  <v-icon
                    v-if="active"
                    color="primary"
                    size="36"
                    style="position: absolute;bottom: 8px;right: 8px;"
                  >
                    mdi-check
                  </v-icon>
                  <div>
                    {{ x.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </v-item>
        </div>
      </v-item-group>
    </div>
    <div class="pa-4">
      <v-btn
        class="primary mr-2"
        @click="moveDishAndDeletePrinter"
      >
        {{ $t('sure') }}
      </v-btn>
      <v-btn
        outlined
        @click="$emit('close')"
      >
        {{ $t('cancel') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
  import Dish from '../../../../model/dish/Dish'
  import hillo from 'hillo'

  export default {
    name: 'DeletePrinterDialog',
    props: {
      selectPrinter: {},
      waitSelectedPrinter: {},
    },
    data () {
      return {
        moveDishesPrinter: null,
      }
    },
    methods: {
      async moveDishAndDeletePrinter () {
        const dishesList = (await Dish.getList(true)).filter(it => it.printCatName === this.selectPrinter.printerName)
        for (const item of dishesList) {
          item.printGroupId = this.waitSelectedPrinter[this.moveDishesPrinter].printerGroupId
          await Dish.edit(item)
        }
        await hillo.post('Printer.php?op=remove', { id: this.selectPrinter.printerId })
        await hillo.post('Printer.php?op=deletePrinterGroup', { id: this.selectPrinter.printerGroupId })
        this.$emit('close')
      },
    },
  }
</script>

<style scoped>

</style>
