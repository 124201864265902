import { IKDataEntity } from 'metaflow-js'
import Printer from './Printer'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'deviceId',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
    form: false,
  },
  printerName: {
    displayName: 'printerName',
  },
  isSingleLinePrint: {
    type: IKDataEntity.Types.Boolean,
    displayName: 'isSingleLinePrint',
  },
  upsideDown: {
    header: false,
    type: IKDataEntity.Types.Boolean,
  },
  defaultRetryCount: {
    formConfig: {
      default: '0',
    },
  },
  printerTypeId: {
    // type: IKDataEntity.Types.Option,
    displayName: 'printerTypeName',
    formConfig: {
      type: {
        itemText: 'typeName',
      },
      requiredEdit: true,
    },
    form: false,

  },
  sn: {
    displayName: 'sn',
  },
  isReceptionPrinter: {
    type: IKDataEntity.Types.Boolean,
    formConfig: {
      default: false,
    },
  },
}

const config = {
  load: async function (filter) {
    const res = await Printer.load(filter)
    return res.filter(i => i.printerTypeId !== '9' && i.printerTypeId !== '10')
  },
  add: function (item) {
    Printer.add(item)
  },
  edit: async function (item) {
    return Printer.edit(item)
  },
  remove: function (id) {
    return Printer.remove(id)
  },
}

export default IKDataEntity.ModelFactory(entity, config)
