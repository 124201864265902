<template>
  <v-navigation-drawer
    v-model="realDialog"
    :width="width"
    fixed
    right
    temporary
    touchless
  >
    <v-container
      class="pa-6"
      style="background-color: #F9FBFC"
    >
      <div class="d-flex align-center mb-8">
        <v-btn
          class="mr-5 rounded"
          height="36px"
          icon
          tile
          width="36px"
          @click="close()"
        >
          <v-icon size="24">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <div class="text-h3 font-weight-bold">
          {{ editedIndex === -1 ? $t('new') : $t('edit')+'/' }}{{ printer?.name ?? '' }}
        </div>
        <v-spacer/>
      </div>


      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <div
          class="pa-6 rounded-xl"
          style="border: 1px solid #E0E0E0; background-color: white"
        >
          <div>
            <div>{{ $t('device_name') }}<span style="color: red">*</span></div>
            <v-text-field
              v-model="localPrinter.name"
              :rules="rules.name"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('cutOrderEachItem') }}<span style="color: red">*</span></div>
            <v-select
              v-model="localPrinter.isSingleOrderPrint"
              :items="item"
              :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
              item-text="text"
              item-value="value"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('ob_upSideDown') }}<span style="color: red">*</span></div>
            <v-select
              v-model="localPrinter.upsideDown"
              :items="item"
              :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
              item-text="text"
              item-value="value"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('auto_repeat_times') }}<span style="color: red">*</span></div>
            <v-text-field
              v-model="localPrinter.defaultRetryCount"
              :rules="rules.count"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('device_SN') }}<span style="color: red">*</span></div>
            <v-text-field
              v-model="localPrinter.sn"
              :rules="rules.sn"
              outlined
            />
          </div>

          <div>
            <div>{{ $t('isReceptionPrinter') }}<span style="color: red">*</span></div>
            <v-select
              v-model="localPrinter.isReceptionPrinter"
              :items="item"
              :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
              item-text="text"
              item-value="value"
              outlined
            />
          </div>
        </div>

        <div class="mt-8 pl-1">
          <v-btn
            :disabled="!valid"
            :loading="loading"
            class="mr-4"
            color="primary"
            elevation="0"
            @click="save"
          >
            {{ $t('save_change') }}
          </v-btn>
        </div>
      </v-form>
    </v-container>

    <!--    <no-chain-scroll-container>-->
    <!--      <slot/>-->
    <!--    </no-chain-scroll-container>-->
  </v-navigation-drawer>
</template>

<script>
  // import IKUtils from 'innerken-js-utils'

  import IKUtils from 'innerken-js-utils'
  import PrinterGroup from '../../../../model/print/PrinterGroup'
  import Printer from '../../../../model/print/Printer'
  import { isEmpty } from 'lodash'
  import DeviceList from '../../../../model/print/DeviceList'

  export default {
    name: 'EditDialog',
    props: {
      model: {
        type: Object,
        default: () => {
        },
      },
      title: {
        type: String,
        default: 'Form',
      },

      value: {
        default: null,
        type: Boolean,
      },
      width: {
        default: 'min(700px, calc(100vw - 300px))',
        type: String,
      },
      printer: {
        type: Object,
        default: () => {
        },
      },
    },

    data () {
      return {
        printerGroupReceptionId: 3, // 前台打印分组Id
        realDialog: {},
        valid: true,
        loading: false,
        editedItem: false,
        item: [{ text: this.$t('no'), value: false }, { text: this.$t('yes'), value: true }],
        widthItems: ['80', '50', '44'],
        rules: {
          name: [v => !!v || 'Item is required'],
          sn: [v => !!v || 'Item is required'],
          count: [v => !!v || 'Item is required'],
          size: [v => !!v || 'Item is required'],
        },
        localPrinter: { name: '' },
        editedIndex: null,
      }
    },


    computed: {
      isNormalPrinter () {
        return this.localPrinter.isPrinter && !this.localPrinter.isStickerPrinter
      },
    },

    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.realDialog = val
          this.localPrinter = Object.assign(this.localPrinter, this.printer)
        },
      },
      realDialog (val) {
        if (!val) {
          this.$emit('input', val)
        }
      },
    },
    methods: {
      close (needRefresh = false, args) {
        this.realDialog = false
        if (needRefresh) {
          this.$emit('need-refresh', args)
        }
      },
      async wait (action) {
        this.loading = true
        try {
          await action()
        } catch (e) {
        }
        this.loading = false
      },
      async save (close = true) {
        if (!this.$refs.form.validate()) {
          return
        }

        this.wait(async () => {
          if (this.editedIndex > -1) {
            // save printerGroup

            let res = await PrinterGroup.edit(this.localPrinter, false)
            console.log(res, 'save PrinterGroup')

            // save printer
            const printer = Object.assign({}, this.localPrinter)
            printer.id = this.localPrinter.printerId
            printer.printerName = this.localPrinter.name
            res = await Printer.edit(printer, false)
            console.log(res, 'save Printer')

            IKUtils.toast(this.$t('edit_success'))

            await this.updateAllDeviceReception()
            this.close(true, res)
          }
        })
        this.$emit('reload')
      },
      async updateAllDeviceReception () {
        if (this.localPrinter.isReceptionPrinter) {
          const receptionPrinter = (await DeviceList.getList()).filter(item => this.localPrinter.printerId !== item.printerId && item.groupId.includes(this.printerGroupReceptionId))

          console.log(receptionPrinter, 'receptionPrinter')
          if (!isEmpty(receptionPrinter)) {
            for (const p of receptionPrinter) {
              const index = p.groupId.findIndex(this.printerGroupReceptionId)

              p.groupId = p.groupId.splice(index, 1)
              await DeviceList.edit(p)
            }
          }
        }
      },

    },


  }
</script>

<style scoped>

</style>
