import { render, staticRenderFns } from "./EditDeviceDialog.vue?vue&type=template&id=488a72ba&scoped=true&"
import script from "./EditDeviceDialog.vue?vue&type=script&lang=js&"
export * from "./EditDeviceDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488a72ba",
  null
  
)

export default component.exports